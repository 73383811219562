import type { MetalOs, MetalOsType } from "@warrenio/api-spec/spec.oats.gen";
import { DataIcon } from "../../../components/icon/DataIcon.tsx";
import { field, type FieldsOf } from "../../FieldConfig.tsx";

export const osFields = {
    os_id: field("OS ID", (e) => e.os_id),
    os_type_id: field("OS type", (e) => e.os_type_id),
    version_title: field("Version title", (e) => e.version_title),
    is_published: field("Published", (e) => e.is_published),
} satisfies FieldsOf<MetalOs>;
export const osTypeFields = {
    os_type_id: field("OS type ID", (e) => e.os_type_id),
    title: field("Title", (e) => e.title),
    icon: field("Icon", (e) => e.icon, {
        render: ({ value }) => <DataIcon mode="mask" className="size-1rem" data={value} />,
    }),
} satisfies FieldsOf<MetalOsType>;
